.bold {
    font-weight: 600;  }
  
  .indent >div >div{
    padding-left: 10px;
  }
  
  .underline {
    border-top: 1px solid #EEE;
  }

  .underline-thicker-light{
    border-top: 1px solid #e8e8e8;
  }

  .underline-thicker-dark{
    border-top: 1px solid #f8f8f8;
  }
  
  .spaceholder {
    height: 20px;
  }

  .header-align-right .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: flex-end;
    padding-right: 16px
  }

  .header-align-center .MuiDataGrid-columnHeaderTitleContainer {
    justify-content:center;
  }

  .header-align-left .MuiDataGrid-columnHeaderTitleContainer {
    justify-content: flex-start;
  }

  .MuiDataGrid-row >div {
    border-top: none !important;
    border-bottom: none !important;
    border-left: none !important;
  }
  
  .MuiDataGrid-columnHeaders {
    border-bottom: 3px solid #EEE !important;
  }

  .add-new-column{
    z-index: 55;
    cursor: pointer;
    color: #818595;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .grid-header-title .MuiDataGrid-columnHeaderTitle{
    color: #8895AF;
    font-family: "Inter";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .column-border {
    border-right: 1px solid #EEE;
  }

.number-of-filters{
    position: absolute;
    width: 15px;
    height: 15px;
    top: -1px;
    right: 0;
    padding-top: 3px;
    transform: translateX(75%);
    background: #495570;
    border-radius: 50%;
    color: #FFF;
    font-family: 'Inter';
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0.15px;
}
