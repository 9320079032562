@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap');


html {
  height: 100%;
}

body {
    font-family: 'Inter', sans-serif !important;
    height: 100%;
  }

a.link:hover {
  text-decoration: underline !important;
}

/* Overrides for BAS DevExtreme DataGrid */
.bas-table-datagrid-override {
  .dx-datagrid .dx-row-lines > td {
    /* border-bottom: 1px solid #515159; */
    border-bottom: none;
  }
}

.dx-datagrid .dx-header-row {
  background: rgba(0, 0, 0, 0.08);
}

div.dx-datagrid .dx-row > td {
  padding: 8px 16px;
}
