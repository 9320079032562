.upload-file-popup-zindex{
    z-index: 1200 !important;
}
.receive-money, .spend-money {
    position: relative;
    margin-top: -1px;
    margin-bottom: 20px;
}

.receive-money:after{
    content: "";
    display: block;
    background-color: #19752D;
    position: absolute;
    top: 0px;
    left: -1px;
    width: 8px;
    bottom: 0px;
    border-radius: 4px 0px 0px 4px;
}

.spend-money:after{
    content: "";
    display: block;
    background-color: #751919;
    position: absolute;
    top: -1px;
    left: -1px;
    width: 8px;
    bottom: -1px;
    border-radius: 4px 0px 0px 4px;
}
